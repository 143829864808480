<template>
  <div class="filter-area">
    <div class="font-weight-bold text-h5 mb-4">Lịch sử thêm linh kiện</div>
    <v-card class="tp-filter-scroll pa-5" flat>
      <div class="">
        <div class="font-weight-bold mb-2">Trạng thái</div>
        <v-radio-group v-model="selectedStatus" class="mt-0" dense hide-details>
          <v-radio label="Tất cả" value="all"></v-radio>
          <v-radio label="Thành công" :value="1"></v-radio>
          <v-radio label="Bị hủy" :value="-1"></v-radio>
        </v-radio-group>
      </div>
      <div class="mt-5">
        <div class="font-weight-bold mb-2">Thời gian</div>
        <tp-input-time-filter
          :type="selectedTime.type"
          :value="selectedTime.value"
          @change="selectedTime = $event"
        ></tp-input-time-filter>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    statusFilter: {
      type: [Number, String]
    },
    timeFilter: {
      type: [Object]
    }
  },
  data() {
    return {};
  },
  computed: {
    selectedStatus: {
      get() {
        return this.statusFilter;
      },
      set(val) {
        this.$emit("updateStatusFilter", val);
      }
    },
    selectedTime: {
      get() {
        return this.timeFilter;
      },
      set(val) {
        this.$emit("updateTimeFilter", val);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.filter-area {
  width: 256px;
}
</style>
