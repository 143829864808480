<template>
  <v-data-table
    v-model="selected"
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="addAccessStatusRequest.value === 'loading-getAddAccesses'"
    loading-text="Đang tải dữ liệu"
    :items="addAccesses"
    item-key="id"
    show-select
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.new_option_sku`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.new_option_sku)"
          >
            {{ item.new_option_sku }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.old_option_name`]="{ item }">
      <div class="p-wrap my-1">{{ getSpecsText(item.old_option_specs) }}</div>
    </template>

    <template v-slot:[`item.new_option_name`]="{ item }">
      <div class="p-wrap my-1">{{ getSpecsText(item.new_option_specs) }}</div>
    </template>

    <template v-slot:[`item.status`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="
          item.status === 1
            ? 'green'
            : item.status === -1
            ? 'red accent-2'
            : 'orange'
        "
        small
        outlined
      >
        {{
          item.status === 1
            ? "Thành công"
            : item.status === -1
            ? "Bị huỷ"
            : "Bị huỷ"
        }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { getSpecsText } from "@/core/composables";

export default {
  props: {
    selectedAddAccesses: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      headers: [
        {
          text: "SKU",
          align: "start",
          sortable: false,
          value: "new_option_sku"
        },
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial_number"
        },
        {
          text: "CH trước thay đổi",
          align: "start",
          sortable: false,
          value: "old_option_name"
        },
        {
          text: "CH sau thay đổi",
          align: "start",
          sortable: false,
          value: "new_option_name"
        },
        {
          text: "Ngày thực hiện",
          align: "center",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Người thực hiện",
          align: "center",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },

  computed: {
    selected: {
      get() {
        return this.selectedAddAccesses;
      },
      set(val) {
        this.$emit("updateSelectedAddAccesses", val);
      }
    },
    addAccesses() {
      return this.$store.getters["ADD_ACCESS/addAccesses"];
    },
    addAccessStatusRequest() {
      return this.$store.getters["ADD_ACCESS/statusRequest"];
    }
  },

  methods: {
    getSpecsText(specs) {
      return getSpecsText(specs, ", ");
    },

    async openModalAccess(product) {
      await this.$store.dispatch("PRODUCT/getProductById", product.id);
      await this.$store.dispatch(
        "PRODUCT_OPTION/getProductOptionsByProductId",
        product.id
      );

      this.$modal.show({
        name: "modal-product"
      });
    },

    async viewDetail(item) {
      await this.$store.dispatch("ADD_ACCESS/getAddAccess", item.id);
      this.$modal.show({
        name: "modal-adding-accessories"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
.p-wrap {
  width: 240px;
  ::v-deep p {
    margin-bottom: 0;
  }
}
</style>
