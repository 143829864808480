<template>
  <div class="action mr-5 mb-4">
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between">
      <div class="action-row--col flex-grow-1 pr-5">
        <tp-search-option
          :options="searchOptions"
          :value="searchKey"
          @search="updateSearchKey($event)"
        ></tp-search-option>
      </div>
      <div class="action-row--col">
        <v-btn
          class="rounded-lg"
          color="primary"
          depressed
          @click="gotoDetailPage()"
        >
          <v-icon left>mdi-plus</v-icon> Thêm mới
        </v-btn>
        <v-btn
          class="ml-3 rounded-lg"
          color="primary"
          dark
          depressed
          :loading="addAccessStatusRequest.value === 'loading-exportReportFile'"
          outlined
          @click="$emit('exportReportFile')"
        >
          <v-icon left>mdi-file-download-outline</v-icon> Xuất file
        </v-btn>
      </div>
    </div>
    <!-- End: Action row -->
    <!-- Start: Action row -->
    <div class="action-row d-flex align-center justify-space-between mt-4">
      <div class="action-row--col align-center">
        <!-- Start: Show filter dropdown -->
        <tp-btn-overflow
          btn-class="white rounded-lg"
          :items="showFilterList"
          :selected-value="selectedShowFilterId"
          @change="selectedShowFilterIndex = $event"
          @select="selectShowFilter($event)"
        ></tp-btn-overflow>
        <!-- End: Show filter dropdown -->
        <!-- Start: Action dropdown -->
        <tp-btn-overflow
          v-if="selectedAddAccesses.length > 0"
          btn-class="white rounded-lg ml-3"
          :active-item="false"
          :items="actionList"
          placeholder="Thao tác"
        ></tp-btn-overflow>
        <!-- End: Action dropdown -->
        <div class="ml-3" v-if="selectedAddAccesses.length > 0">
          Đã chọn <strong>{{ this.selectedAddAccesses.length }}</strong>
        </div>
      </div>
      <div class="action-row--col d-flex align-center">
        <div class="text-body-2">
          {{ paginationInfo.from }} - {{ paginationInfo.to }} trong số
          {{ paginationInfo.itemTotal }}
        </div>
        <v-pagination
          v-model="curPage"
          color="primary"
          :length="paginationInfo.pageTotal"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
    <!-- End: Action row -->
  </div>
</template>

<script>
export default {
  props: {
    currentPage: {
      type: Number
    },
    selectedAddAccesses: {
      type: Array,
      required: true
    },
    selectedShowFilterId: {
      type: Number
    },
    searchKey: {
      type: String
    }
  },

  data() {
    return {
      actionList: [{ id: "delete", text: "Xóa" }],
      searchOptions: [
        { type: "search", label: "Tìm kiếm theo serial" }
        // { type: "option/search", label: "Theo mã, tên SKU" },
        // { type: "serial/search", label: "Theo serial" }
      ],
      showFilterList: [
        { id: 25, text: "Hiển thị 25" },
        { id: 50, text: "Hiển thị 50" },
        { id: 100, text: "Hiển thị 100" }
      ]
    };
  },

  computed: {
    curPage: {
      get() {
        return this.currentPage;
      },
      set(val) {
        this.$emit("updateCurrentPage", val);
      }
    },
    paginationInfo() {
      return this.$store.getters["ADD_ACCESS/paginationInfo"];
    },
    addAccessStatusRequest() {
      return this.$store.getters["ADD_ACCESS/statusRequest"];
    }
  },

  methods: {
    gotoDetailPage() {
      this.$router.push({ name: "goods_adding-accessories-create" });
    },

    selectShowFilter(val) {
      this.$emit("updateItemPerPage", val.id);
    },

    updateSearchKey(val) {
      this.$emit("updateSearchKey", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.action {
  &-row {
    &--col {
      display: flex;
    }
  }
}
</style>
